import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class PopularToursView extends View {
  _parentElementId = "#popularTours";
  _errorMessage =
    "Une erreur est survenue lors de la récupération des circuits populaires.";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;

    return `
          <div class="home__popular-tour" >
          <img
            src="${tour.imageCover.url}"
            alt="${tour.imageCover.alt}"
            class="home__popular-img"
          />
          <h3 class="home__popular-tour-title">${
            tour.name[0].toUpperCase() + tour.name.slice(1)
          }</h3>
          <ul class="home__popular-tour-list">
            <li class="home__popular-tour-item">${tour.duration} jours</li>
            <li class="home__popular-tour-item">${
              tour.maxGroupSize
            } places disponibles</li>

            <li class="home__popular-tour-item">${
              tour.itinerary.length
            } étapes inoubliables</li>
          </ul>
          <a href="/tours/${tour._id}" class="home__popular-tour-btn btn"
            >Voir le circuit</a
          >
        </div>
      `;
  }
}

export default new PopularToursView();
