import { Map, MapOptions } from "mapbox-gl";

export default class MapBoxUtils {
  public async getCoordinatesForCountry(
    country: string
  ): Promise<[number, number]> {
    const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        country
      )}.json?access_token=${accessToken}&language=fr`
    );
    const data = await response.json();

    if (data.features && data.features.length > 0) {
      const [lng, lat] = data.features[0].center;
      return [lng, lat];
    }

    throw new Error("Coordinates not found");
  }

  protected createMarker() {
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.style.backgroundColor = "#ff7730";
    el.style.width = "30px";
    el.style.height = "30px";
    el.style.borderRadius = "50%";
    return el;
  }

  protected createMap(mapOption: MapOptions): Map {
    const map = new Map({
      container: mapOption.container,
      style: mapOption.style,
      projection: mapOption.projection,
      zoom: mapOption.zoom,
      center: mapOption.center ?? [30, 15],
      attributionControl: false,
      language: "fr",
      accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    });
    return map;
  }
}
