import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class TopToursView extends View {
  _parentElementId = "#topTours";
  _errorMessage =
    "Une erreur s'est produite lors du chargement des destinations phare";

  _generateMarkup(): string {
    return (this._data as TourInterface[])
      .map((tour: TourInterface) => {
        return `
        <li class="home__top-item">
            <img
              src="${tour.imageCover.url}"
              alt="${tour.imageCover.alt}"
              class="home__top-img"
            />
            <div class="home__top-block">
              <span class="home__top-country">${
                tour.name[0].toUpperCase() + tour.name.slice(1)
              }</span>
              <a href="/tours/${
                tour._id
              }" class="btn home__top-link">Details</a>
            </div>
          </li>
        `;
      })
      .join("");
  }
}

export default new TopToursView();
