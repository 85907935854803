import { mapBoxConfig, TourOverviewInterface } from "@/shared/interfaces";
import mapboxgl, { FogSpecification, Map } from "mapbox-gl";
import MapboxUtils from "@/shared/utils/mapbox/Mapbox.utils";

export class MapBoxToursOverview extends MapboxUtils {
  public tours: TourOverviewInterface[];
  public coordinates: Array<[number, number]>;
  private mapContainer = document.getElementById("map");

  constructor(
    tours: TourOverviewInterface[],
    coordinates: Array<[number, number]>
  ) {
    super();
    this.tours = tours;
    this.coordinates = coordinates;
  }

  private options: mapBoxConfig = {
    secondsPerRevolution: 240,
    maxSpinZoom: 5,
    slowSpinZoom: 3,
    userInteracting: false,
    spinEnabled: true,
  };

  private mapBoxEvents(map: Map): void {
    map.on("mousedown", () => {
      this.options.userInteracting = true;
    });
    map.on("dragstart", () => {
      this.options.userInteracting = true;
    });
    map.on("moveend", () => {
      this.spinGlobe(map);
    });
  }

  private mapBoxConfig(map: Map): void {
    map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.disable();
    map.doubleClickZoom.disable();
    map.boxZoom.disable();
    map.touchZoomRotate.disable();
  }

  private spinGlobe(map: mapboxgl.Map): void {
    const zoom = map.getZoom();
    if (
      this.options.spinEnabled &&
      !this.options.userInteracting &&
      zoom < this.options.maxSpinZoom
    ) {
      let distancePerSecond = 360 / this.options.secondsPerRevolution;
      if (zoom > this.options.slowSpinZoom) {
        const zoomDif =
          (this.options.maxSpinZoom - zoom) /
          (this.options.maxSpinZoom - this.options.slowSpinZoom);
        distancePerSecond *= zoomDif;
      }
      const center = map.getCenter();
      center.lng -= distancePerSecond;
      map.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }

  private frogOption: FogSpecification = {
    "space-color": "#eee",
    "high-color": "#fdfdfd",
  };

  private setMarker(
    map: Map,
    tours: TourOverviewInterface[],
    coordinates: Array<[number, number] | null>
  ) {
    tours.forEach((tour, index) => {
      const coordinate = coordinates[index];
      if (coordinate) {
        const marker = this.createMarker();
        const popupContent = this.htmlPopupContent(tour);

        new mapboxgl.Marker(marker)
          .setLngLat(coordinate)
          .setPopup(new mapboxgl.Popup().setHTML(popupContent))
          .addTo(map);
      }
    });
  }

  public async setMap() {
    if (!this.mapContainer) return;

    const mapOption = {
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      projection: "globe",
      zoom: 1,
    };

    const map = this.createMap(mapOption);
    this.mapBoxConfig(map);

    map.on("style.load", () => {
      map.setFog(this.frogOption);
      this.setMarker(map, this.tours, this.coordinates);
    });

    this.mapBoxEvents(map);
    this.spinGlobe(map);
  }

  private htmlPopupContent(tour: TourOverviewInterface): string {
    const popupContent = `
          <div class="mapBox__marker">
            <h3 class="mapBox__marker-title">
            ${tour.name[0].toUpperCase() + tour.name.slice(1)}
            </h3>
              <img class="mapBox__marker-img" src="${tour.imageCover.url}" 
              alt="${tour.imageCover.alt}" >

          </div>
        `;
    return popupContent;
  }
}
