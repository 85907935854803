import { ItineraryTourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class PreviewItineraryTour extends View {
  _parentElementId = "";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération de l'itinéraire du voyage.";

  _generateMarkup(): string {
    const tour = this._data as ItineraryTourInterface;

    return `  
    <li class="tour__itinerary-item">
      <div class="tour__itinerary-point">&nbsp;</div>
      <div class="tour__itinerary-card">
        <span class="tour__itinerary-card-day">${tour.day} ${
      tour.day <= 1 ? "jour" : "jours"
    }</span>
        <h4 class="tour__itinerary-card-city">${
          tour.title[0].toUpperCase() + tour.title.slice(1)
        }</h4>
        <p class="tour__itinerary-card-text">${tour.description}</p>
      </div>
    </li>`;
  }
}

export default new PreviewItineraryTour();
