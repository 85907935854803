export default abstract class View {
  protected abstract _parentElementId: string;
  protected _data: any;
  abstract _errorMessage: string;

  abstract _generateMarkup(): string;
  /**
   * Generates the HTML markup for the view.
   *
   * @returns {string} The generated HTML markup as a string.
   */
  abstract _generateMarkup(): string;

  get _parentElement(): HTMLElement | null {
    return document.querySelector(this._parentElementId);
  }

  /**
   * Renders the provided data into the view.
   *
   * @param data - The data to be rendered. Can be of any type.
   * @param render - A boolean indicating whether to render the markup immediately. Defaults to true.
   * @returns {void | string} - Returns the generated markup as a string if `render` is false or if `_parentElement` is not found. Otherwise, returns void.
   */
  render(data: any, render = true): void | string {
    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();

    this._data = data;

    const markup = this._generateMarkup();

    if (!render || !this._parentElement) return markup;

    this.clear();
    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  /**
   * Clears the content of the parent HTML element.
   *
   * This function sets the inner HTML of the parent element to an empty string,
   * effectively removing all child elements and content within the parent element.
   *
   * @returns {void} - This function does not return any value.
   */
  clear(): void {
    if (this._parentElement) {
      this._parentElement.innerHTML = "";
    }
  }

  /**
   * Renders an error message into the view.
   *
   * This function generates an HTML markup for an error message and inserts it into the parent element.
   * If a custom message is provided, it will be displayed; otherwise, the default error message is used.
   *
   * @param message - The error message to be displayed. Defaults to the class's `_errorMessage` property.
   * @returns {void} - This function does not return any value.
   */
  renderError(message = this._errorMessage): void {
    const markup = `
    <div class="error">
      <div class="error__background">&nbsp;</div>
      <div class="error__block">
        <svg class="error__icon">
          <use xlink:href="/public/icons/sprite.svg#icon-alert-triangle"></use>
        </svg>
        <p class="error__message">${message}</p>
        <button class="btn error__btn" onclick="this.closest('.error').remove()">Fermer</button>
      </div>
    </div>
  `;

    this.clear();
    if (this._parentElement) {
      this._parentElement.insertAdjacentHTML("afterbegin", markup);
    }
  }

  /**
   * Renders a spinner element into the view to indicate a loading state.
   *
   * This function generates an HTML markup for a spinner and inserts it into the parent element.
   * The spinner consists of an image and a text indicating that loading is in progress.
   *
   * @returns {void} - This function does not return any value.
   */
  renderSpinner(): void {
    const markup = `
    <div class="spinner">
      <img src="/icons/plane.png" alt="icone de chargement" class="spinner__img">
      <p class="spinner__text">Chargement en cours...</p>
    </div>
`;

    this.clear();
    if (this._parentElement) {
      this._parentElement.insertAdjacentHTML("afterbegin", markup);
    }
  }

  /**
   * Removes the spinner element from the parent HTML element.
   *
   * This function searches for a spinner element within the parent element and removes it after a delay of 1.5 seconds.
   *
   * @returns {void} - This function does not return any value.
   */
  removeSpinner(): void {
    const spinner = this._parentElement?.querySelector(".spinner");
    if (spinner) {
      setTimeout(() => spinner.remove(), 1500);
    }
  }
}
