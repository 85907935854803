import { ItineraryTourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";
import { MapBoxItineraryTour } from "@/shared/utils/mapbox/MapboxItineraryTour.utils";

class MapItineraryTour extends View {
  _parentElementId = "#mapBoxItineraryTour";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération de la carte  d'itinéraire du voyage.";

  _generateMarkup(): string {
    return "";
  }

  renderMap(itinerary: ItineraryTourInterface[]) {
    new MapBoxItineraryTour(itinerary).setMap();
  }
}

export default new MapItineraryTour();
