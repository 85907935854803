import { CustomHeadersInit, MethodsType } from "@/shared/types";
import { SendRequestResponse } from "@/shared/interfaces";

/**
 * Sends an HTTP request to the specified URL with the given method and optional body.
 *
 * @param {string} url - The URL to which the request is sent.
 * @param {MethodsType} method - The HTTP method to use for the request (e.g., 'GET', 'POST').
 * @param {object} [body] - An optional object to be sent as the request body. It will be stringified to JSON.
 * @returns {Promise<SendRequestResponse>} - A promise that resolves to the response of the request.
 * @throws {Error} - Throws an error if the response status is not ok (i.e., not in the range 200-299).
 */
export async function sendRequest(
  url: string,
  method: MethodsType,
  body?: object
): Promise<SendRequestResponse> {
  try {
    const headersOptions: CustomHeadersInit = {
      "Content-Type": "application/json",
      "x-api-key": import.meta.env.VITE_API_KEY,
      "x-api-key-id": import.meta.env.VITE_ID_API_KEY,
    };

    const headers = new Headers(headersOptions);

    const options: RequestInit = {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending request:", error);
    throw error;
  }
}
