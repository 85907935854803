import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class TourPhotoView extends View {
  _parentElementId = "#tourPhoto";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération des photos du voyages";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;

    return tour.images
      .map(
        (photo) =>
          `  
        <li class="tour__photo-item">
          <img
            src="${photo.url}"
            alt="${photo.alt}"
            class="tour__photo-img"
          />
        </li>
        `
      )
      .join("");
  }
}

export default new TourPhotoView();
