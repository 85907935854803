import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class AllToursView extends View {
  _parentElementId = "#allTours";
  _errorMessage =
    "Une erreur est survenue lors de la récupération des destinations.";

  _generateMarkup(): string {
    const tours = this._data as TourInterface[];

    return tours
      .map((tour) => {
        return `    
        <li class="tours__destination-item">
          <img
            src="${tour.imageCover.url}"
            alt="${tour.imageCover.alt}"
            class="tours__destination-img"
          />
          <div class="tours__destination-block">
            <span class="tours__destination-country">${
              tour.name[0].toUpperCase() + tour.name.slice(1)
            }</span>
            <a href="/tours/${tour._id}" class="btn">Details</a>
          </div>
        </li>`;
      })
      .join("");
  }
}

export default new AllToursView();
