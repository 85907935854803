import HomeModel from "@/models/home.model";
import State from "@/shared/state/State";
import PopularToursView from "@/views/home/PopularTours.view";
import TopToursView from "@/views/home/TopTours.view";

const getTopTours = async (): Promise<void> => {
  try {
    const limit = 4;
    await HomeModel.getTopTours(limit);

    const topTours = State.getInstance().getTopTours();

    TopToursView.render(topTours);
  } catch (error) {
    TopToursView.renderError(TopToursView._errorMessage);
  }
};

const getMostPopularTours = async () => {
  try {
    await HomeModel.getMostPopularTours();

    const popularTours = State.getInstance().getMostPopularTour();

    PopularToursView.render(popularTours);
  } catch (error) {
    PopularToursView.renderError(PopularToursView._errorMessage);
  }
};

export const homeController = async () => {
  await Promise.all([getTopTours(), getMostPopularTours()]);
};
