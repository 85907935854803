import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";


class IntroTourView extends View {
  _parentElementId = "#introTourDetails";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération des détails de la présentation du voyage.";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;
    return `
    <div class="tour__intro-filter">&nbsp;</div>

      <img
      src="${tour.imageCover.url}"
      alt="${tour.imageCover.alt}"
      class="tour__intro-background"
    />

    <div class="tour__intro-content">
      <h1 class="tour__intro-title">${tour.name[0].toUpperCase() + tour.name.slice(1)}</h1>
      <p class="tour__intro-description">${tour.summary}</p>
    </div>

    <div class="tour__intro-card">
      <span class="tour__intro-card-text">À partir de</span>
      <span class="tour__intro-card-price">${tour.price}€ / pers</span>
      <span class="tour__intro-card-text">${tour.duration} ${
      tour.duration <= 1 ? " jour" : " jours"
    } - ${tour.itinerary.length} ${
      tour.itinerary.length <= 1 ? "étape" : "étapes"
    }</span>
      <button class="btn tour__intro-card-btn">Réserver</button>
    </div>
    `;
  }
}

export default new IntroTourView();
