import { sendRequest } from "@/shared/utils";
import State from "@/shared/state/State";

export default class HomeModel {
  static async getTopTours(limit?: number): Promise<void> {
    const tours = await sendRequest(
      `${import.meta.env.VITE_API_URL_TRAVEL}/tours/getTopToursByRating?limit=${
        limit || 10
      }`,
      "GET"
    );

    State.getInstance().setTopTours(tours.data.tours);
  }

  static async getMostPopularTours(): Promise<void> {
    const popularTour = await sendRequest(
      `${import.meta.env.VITE_API_URL_TRAVEL}/tours/getMostPopularTours`,
      "GET"
    );

    State.getInstance().setMostPopularTour(popularTour.data.tour);
  }
}
