import { TourOverviewInterface } from "@/shared/interfaces";
import State from "@/shared/state/State";
import { sendRequest } from "@/shared/utils";
import MapBoxUtils from "@/shared/utils/mapbox/Mapbox.utils";

export default class TourModel {
  static async getAllTours(): Promise<void> {
    const tours = await sendRequest(
      `${import.meta.env.VITE_API_URL_TRAVEL}/tours`,
      "GET"
    );

    State.getInstance().setAllTours(tours.data);
  }

  static getFeaturedTours(limit: number): void {
    const tours = State.getInstance().getAllTours();

    const featuredTours = tours
      .sort((a, b) => b.ratingsAverage - a.ratingsAverage)
      .slice(0, limit);

    State.getInstance().setFeaturedTours(featuredTours);
  }

  static async getToursOverview(): Promise<void> {
    const tours = State.getInstance()
      .getAllTours()
      .map((tour) => {
        return {
          name: tour.name,
          imageCover: tour.imageCover,
        };
      });

    State.getInstance().setToursOverview(tours);
  }

  static async getToursCoordinates(
    tours: TourOverviewInterface[]
  ): Promise<[number, number][]> {
    const coordinates = await Promise.all(
      tours.map(async (tour) => {
        return await new MapBoxUtils().getCoordinatesForCountry(tour.name);
      })
    );

    return coordinates;
  }

  static async getTourById(id: string): Promise<void> {
    const tour = await sendRequest(
      `${import.meta.env.VITE_API_URL_TRAVEL}/tours/${id}`,
      "GET"
    );

    State.getInstance().setTour(tour.data);
  }
}
