import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";
import { formatDate } from "@/shared/utils";

class DetailsTourView extends View {
  _parentElementId = "#tourDetails";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération des détails du voyage";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;
    const difficulty =
      tour.difficulty === "easy" ? 1 : tour.difficulty === "medium" ? 2 : 3;

    return `
       <div class="tour__details-block" >
          <div class="tour__details-info">
            <h4 class="tour__details-subtitle" >Détails du voyage</h4>
            <ol class="tour__details-info-list" >
                  <li class="tour__details-info-item">
                <span class="tour__details-info-text">Durée</span>
                <span class="tour__details-info-data">${tour.duration} ${
      tour.duration <= 1 ? "jour" : "jours"
    }</span>
              </li>
              <li class="tour__details-info-item">
                <span class="tour__details-info-text">Taille du groupe</span>
                <span class="tour__details-info-data"> ${
                  tour.maxGroupSize
                } pers</span>
              </li>
              <li class="tour__details-info-item">
                <span class="tour__details-info-text">${
                  tour.itinerary.length <= 1 ? "Étape" : "Étapes"
                }</span>
                <span class="tour__details-info-data">${
                  tour.itinerary.length
                }</span>
              </li>
              <li class="tour__details-info-item">
                <span class="tour__details-info-text">${
                  tour.guides.length <= 1 ? "Guide" : "Guides"
                }</span>
                <span class="tour__details-info-data">${
                  tour.guides.length
                }</span>
              </li>
              <li class="tour__details-info-item">
                <span class="tour__details-info-text">Difficulté</span>
                <span class="tour__details-info-data">

               ${Array(difficulty)
                 .fill("")
                 .map(
                   () => `
                  <svg class="tour__details-info-icon">
                    <use xlink:href="/icons/sprite.svg#icon-sports-shoe"></use>
                  </svg>`
                 )
                 .join("")}

                </span>
              </li>
              <li class="tour__details-info-item">
                <span class="tour__details-info-text">${
                  tour.startDates.length <= 1 ? "Date" : "Dates"
                } de départ</span>
                <span class="tour__details-info-data">${
                  tour.startDates.length
                }</span>
              </li>
            </ol>
          </div>

            <div class="tour__details-guides">
            <h4 class="tour__details-guides-title tour__details-subtitle">
              Les guides
            </h4>

<div class="tour__details-guides-content">
 ${tour.guides
   .map(
     (guide) => `
                 <div class="tour__details-guides-block">
              <img
                src="${guide.photo.url}"
                alt="${guide.photo.alt}"
                class="tour__details-guides-img"
              />
              <span class="tour__details-guides-name">${
                guide.firstname[0].toUpperCase() + guide.firstname.slice(1)
              } ${
       guide.lastname[0].toUpperCase() + guide.lastname.slice(1)
     }</span>
            </div>
              `
   )
   .join("")}
        </div>

        </div>

        </div>
        <div class="tour__details-line">&nbsp;</div>

        <div class="tour__details-block">
          <div class="tour__details-startDates">
            <h4 class="tour__details-startDates-title tour__details-subtitle">
              ${tour.startDates.length <= 1 ? "Date" : "Dates"} de départ
            </h4>
         <ul class="tour__details-startDates-list">
          ${tour.startDates
            .map(
              (date) => `
            <li class="tour__details-startDates-item">
              <span class="tour__details-startDates-text">${formatDate(
                date
              )}</span>
            </li>`
            )
            .join("")}
        </ul>
          </div>

          <button class="tour__details-btn">Réserver</button>
        </div>

      `;
  }
}

export default new DetailsTourView();
