import View from "@/views/Views.view";

class LoadingView extends View {
  _parentElementId = "#loading";
  _errorMessage = "Chargement en cours...";

  _generateMarkup(): string {
    return "";
  }
}

export default new LoadingView();
