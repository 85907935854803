import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";

class FeaturedDestinationsView extends View {
  _parentElementId = "#featuredTours";
  _errorMessage =
    "Une erreur est survenue lors de la récupération des destinations en vedettes";
  _generateMarkup(): string {
    const tours = this._data as TourInterface[];
    return tours
      .map((tour) => {
        return `
          <li class="tours__featured-tours-card">
              <img
                src="${tour.imageCover.url}"
                alt="${tour.imageCover.alt}"
                class="tours__featured-tours-img"
              />
              <div class="tours__featured-tours-block">
                <h4 class="tours__featured-tours-title">${
                  tour.name[0].toUpperCase() + tour.name.slice(1)
                }</h4>
                <a href="/tours/${
                  tour._id
                }" class="btn tours__featured-tours-link">Details</a>
              </div>
            </li>
      `;
      })
      .join("");
  }
}

export default new FeaturedDestinationsView();
