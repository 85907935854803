import { ItineraryTourInterface, TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";
import PreviewItineraryTourView from "@/views/tour/PreviewItineraryTour.view";

class ItineraryTourView extends View {
  _parentElementId = "#itineraryTour";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération de l'itinéraire du voyage.";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;
    return tour.itinerary
      .map((result: ItineraryTourInterface) =>
        PreviewItineraryTourView.render(result, false)
      )
      .join("");
  }
}

export default new ItineraryTourView();
