import TourModel from "@/models/tours.model";
import { TourInterface } from "@/shared/interfaces";
import State from "@/shared/state/State";
import loadingView from "@/views/loading.view";
import DescriptionTourViews from "@/views/tour/DescriptionTour.view";
import DetailsTourView from "@/views/tour/DetailsTour.view";
import IntroTourViews from "@/views/tour/IntroTour.view";
import ItineraryTourView from "@/views/tour/ItineraryTour.view";
import MapItineraryTourView from "@/views/tour/MapItineraryTour.view";
import TourPhotoView from "@/views/tour/TourPhoto.view";

const renderView = async (view: any, errorMessage: string): Promise<void> => {
  try {
    const tour = State.getInstance().getTour();
    view.render(tour);
  } catch (error) {
    view.renderError(errorMessage);
  }
};

const getIntroTour = async (): Promise<void> => {
  await renderView(IntroTourViews, IntroTourViews._errorMessage);
};

const getDescriptionTour = async (): Promise<void> => {
  await renderView(DescriptionTourViews, DescriptionTourViews._errorMessage);
};

const getItineraryTour = async (): Promise<void> => {
  await renderView(ItineraryTourView, ItineraryTourView._errorMessage);
};

const getTourDetails = async (): Promise<void> => {
  await renderView(DetailsTourView, DetailsTourView._errorMessage);
};

const getTourPhoto = async (): Promise<void> => {
  await renderView(TourPhotoView, TourPhotoView._errorMessage);
};

const getMapBoxItineraryTour = async (): Promise<void> => {
  try {
    loadingView.renderSpinner();
    const tour = State.getInstance().getTour() as TourInterface;

    MapItineraryTourView.renderMap(tour.itinerary);
  } catch (error) {
    MapItineraryTourView.renderError(MapItineraryTourView._errorMessage);
  } finally {
    loadingView.removeSpinner();
  }
};

export const tourController = async (id: string) => {
  if (!id) return;
  //vérifier si l'id est de type objectId
  await TourModel.getTourById(id);

  await Promise.all([
    getIntroTour(),
    getDescriptionTour(),
    getMapBoxItineraryTour(),
    getItineraryTour(),
    getTourDetails(),
    getTourPhoto(),
  ]);
};
