import TourModel from "@/models/tours.model";
import State from "@/shared/state/State";
import loadingView from "@/views/loading.view";
import AllToursView from "@/views/tours/AllTours.view";
import FeaturedToursView from "@/views/tours/FeaturedTours.view";
import MapOverviewView from "@/views/tours/MapOverview.view";

const getAllTours = async (): Promise<void> => {
  try {
    await TourModel.getAllTours();

    const allTours = State.getInstance().getAllTours();

    AllToursView.render(allTours);
  } catch (error) {
    AllToursView.renderError(AllToursView._errorMessage);
  }
};

const getFeaturedTours = (): void => {
  try {
    const limit = 5;
    TourModel.getFeaturedTours(limit);

    const featuredTours = State.getInstance().getFeaturedTours();

    FeaturedToursView.render(featuredTours);
  } catch (error) {
    FeaturedToursView.renderError(FeaturedToursView._errorMessage);
  }
};

const loadMapBoxOverview = async (): Promise<void> => {
  try {
    loadingView.renderSpinner();
    await TourModel.getToursOverview();
    const tours = State.getInstance().getToursOverview();
    const coordinates = await TourModel.getToursCoordinates(tours);

    MapOverviewView.renderMap(tours, coordinates);
  } catch (error) {
    MapOverviewView.renderError(MapOverviewView._errorMessage);
  } finally {
    loadingView.removeSpinner();
  }
};

export const toursController = async () => {
  await getAllTours();
  getFeaturedTours();
  await loadMapBoxOverview();
};
