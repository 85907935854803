import { TourInterface, TourOverviewInterface } from "@/shared/interfaces";

export default class State {
  private static instance: State;
  private topTours: TourInterface[] = [];
  private mostPopularTour: TourInterface | {} = {};
  private allTours: TourInterface[] = [];
  private featuredTours: TourInterface[] = [];
  private toursOverview: TourOverviewInterface[] = [];
  private tour: TourInterface | {} = {};
  private constructor() {}

  public static getInstance(): State {
    if (!State.instance) {
      State.instance = new State();
    }
    return State.instance;
  }

  public setTopTours(tours: TourInterface[]): void {
    this.topTours = tours;
  }

  public getTopTours(): TourInterface[] {
    return this.topTours;
  }

  public setMostPopularTour(tour: TourInterface): void {
    this.mostPopularTour = tour;
  }

  public getMostPopularTour(): TourInterface | {} {
    return this.mostPopularTour;
  }

  public setAllTours(tours: TourInterface[]): void {
    this.allTours = tours;
  }
  public getAllTours(): TourInterface[] {
    return this.allTours;
  }

  public setFeaturedTours(tours: TourInterface[]): void {
    this.featuredTours = tours;
  }

  public getFeaturedTours(): TourInterface[] {
    return this.featuredTours;
  }

  public setToursOverview(tours: TourOverviewInterface[]): void {
    this.toursOverview = tours;
  }

  public getToursOverview(): TourOverviewInterface[] {
    return this.toursOverview;
  }

  public setTour(tour: TourInterface): void {
    this.tour = tour;
  }

  public getTour(): TourInterface | {} {
    return this.tour;
  }
}
