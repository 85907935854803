import page from "page";
import { homeController, toursController, tourController } from "./controllers";
import { homeMeta, toursMeta, tourMeta, notFoundMeta } from "./shared/meta";
import HtmlLoader from "./shared/utils/HtmlLoader.utils";
import State from "./shared/state/State";
import { TourInterface } from "./shared/interfaces";

page("/", async () => {
  await HtmlLoader.loadPage("/src/pages/home.html", homeMeta);
  await homeController();
});
page("/home", async () => {
  await HtmlLoader.loadPage("/src/pages/home.html", homeMeta);
  await homeController();
});
page("/tours", async () => {
  await HtmlLoader.loadPage("/src/pages/tours.html", toursMeta);
  await toursController();
});
page("/tours/:id", async (ctx) => {
  const { id } = ctx.params;

  await HtmlLoader.loadPage("/src/pages/tour.html", tourMeta);

  await tourController(id);
});

page("*", async () => {
  await HtmlLoader.loadPage("/src/pages/notFound.html", notFoundMeta);
});

page();
