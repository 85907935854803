import { TourOverviewInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";
import { MapBoxToursOverview } from "@/shared/utils/mapbox/MapboxToursOverview.utils";

class MapOverview extends View {
  _errorMessage =
    "Une erreur est survenue lors du chargement de la carte intéractive.";
  _parentElementId = "#map";

  _generateMarkup(): string {
    return "";
  }

  renderMap(
    tours: TourOverviewInterface[],
    coordinates: Array<[number, number]>
  ): void {
    new MapBoxToursOverview(tours, coordinates).setMap();
  }
}

export default new MapOverview();
