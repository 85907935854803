import { Map, Marker, Popup } from "mapbox-gl";
import { ItineraryTourInterface } from "@/shared/interfaces";
import MapBoxUtils from "@/shared/utils/mapbox/Mapbox.utils";

export class MapBoxItineraryTour extends MapBoxUtils {
  private map: Map | null = null;
  private mapContainer = document.getElementById("mapBoxItineraryTour");
  public itinerary: ItineraryTourInterface[];

  constructor(itinerary: ItineraryTourInterface[]) {
    super();
    this.itinerary = itinerary;
  }

  private createHtml = (tour: ItineraryTourInterface): string => {
    return `
      <div class="marker">
        <span class="marker__day">Jour ${tour.day}</span>
        <span class="marker__title">${
          tour.title[0].toUpperCase() + tour.title.slice(1)
        }</span>
      </div>
    `;
  };

  private setMarker(): void {
    if (!this.mapContainer) return;

    if (!this.map) {
      const mapOption = {
        container: "mapBoxItineraryTour",
        style: "mapbox://styles/camei8ht/clk2er37k00f101pefd7kd2oc",
        zoom: 4,
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
      };

      this.map = this.createMap(mapOption);
    }

    this.itinerary.forEach((tour) => {
      if (!this.map) return;
      const coordinate = tour.coordinate;

      if (coordinate) {
        const marker = this.createMarker();
        const popupContent = this.createHtml(tour);

        new Marker(marker)
          .setLngLat([coordinate[1], coordinate[0]])
          .setPopup(new Popup().setHTML(popupContent))
          .addTo(this.map);
      }
    });

    if (this.itinerary.length > 0) {
      const firstCoordinate = this.itinerary[0].coordinate;
      if (firstCoordinate) {
        this.map.setCenter([firstCoordinate[1], firstCoordinate[0]]);
      }
    }
  }

  public setMap(): void {
    if (!this.mapContainer) return;

    if (!this.map) {
      const mapOption = {
        container: "mapBoxItineraryTour",
        style: "mapbox://styles/camei8ht/clk2er37k00f101pefd7kd2oc",
        zoom: 4,
      };

      this.map = this.createMap(mapOption);
    }

    this.setMarker();
  }
}
