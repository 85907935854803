import { TourInterface } from "@/shared/interfaces";
import View from "@/views/Views.view";


class DescriptionTourView extends View {
  _parentElementId = "#descriptionTour";
  _errorMessage =
    "Une erreur s'est produite lors de la récupération de la description voyage.";

  _generateMarkup(): string {
    const tour = this._data as TourInterface;
    return `
  <div class="tour__description-block" >
          <h4 class="tour__description-subtitle">
            Votre voyage, vos souvenir, notre mission...
          </h4>
          <p class="tour__description-text">
            ${tour.description}
          </p>
        </div>

        <img
          src="${tour.images[0].url}"
          alt="${tour.images[0].alt}"
          class="tour__description-img"
        />
    `;
  }
}

export default new DescriptionTourView();
